.cm-s-scriban.CodeMirror {
    border: 1px solid #eee;
    height: auto
}
.cm-s-scriban.CodeMirror { background: #000000; color: #eaeaea; }
.cm-s-scriban div.CodeMirror-selected { background: #4b4949; }
.cm-s-scriban .CodeMirror-gutters { background: #000000; border-right: 0px; }
.cm-s-scriban .CodeMirror-guttermarker { color: #e78c45; }
.cm-s-scriban .CodeMirror-guttermarker-subtle { color: #777; }
.cm-s-scriban .CodeMirror-linenumber { color: #999; }
.cm-s-scriban .CodeMirror-cursor { border-left: 1px solid #6A6A6A; }

.cm-s-scriban span.cm-comment { color: #65df47; }
.cm-s-scriban span.cm-atom { color: #a16a94; }
.cm-s-scriban span.cm-number { color: #a16a94; }

.cm-s-scriban span.cm-property, .cm-s-scriban span.cm-attribute { color: #99cc99; }
.cm-s-scriban span.cm-keyword { color: #b9ca4a; }
.cm-s-scriban span.cm-string { color: #e7c547; }

.cm-s-scriban span.cm-none { color: #fff; }
.cm-s-scriban span.cm-variable { color: #b9ca4a; }
.cm-s-scriban span.cm-variable-2 { color: #7aa6da; }
.cm-s-scriban span.cm-def { color: #65df47; }
.cm-s-scriban span.cm-bracket { color: #eaeaea; }
.cm-s-scriban span.cm-tag { color: #d54e53; }
.cm-s-scriban span.cm-link { color: #a16a94; }
.cm-s-scriban span.cm-error { color: #d54e53; }

.cm-s-scriban .CodeMirror-activeline-background { background: #2a2a2a; }
.cm-s-scriban .CodeMirror-matchingbracket { text-decoration: underline; color: white !important; }
