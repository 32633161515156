.loading {
  position: absolute;
  width: 94%;
  top: 0;
  right: 0;
  min-height: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.loading > div {
  height: 10px;
}
