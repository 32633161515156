.page .page__body {
  margin-top: 25px;
}

.page .page__title {
  margin-top: 15px;
}

.page .audit-info {
  position: absolute;
  top: 10px;
  right: 5px;
  text-align: right;
}

.page .page__header > .loading {
  z-index: -1;
  width: 98%;
  right: -15px;
}
