.form {
  margin: 20px 40px 20px;
}
.form.compact {
  margin: 10px;
}
.form .form__input input[type="radio"],
.form .form__input input[type="checkbox"] {
  margin: 7px;
}
.form .form__input-checkbox {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: auto;
  min-height: 1.1876em;
}
.form .form__input-checkbox label {
  width: 270px;
  margin: 0;
}
.form .form__input-checkbox label :first-child {
  padding: 2px;
}

.form__body > div {
  display: flex;
}
.form__body > div > div {
  flex: 1 100%;
}

.form__message {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  text-align: center;
  font-weight: 600;
}

.form__message.error-list {
  text-align: left;
}

.form__message.error-list ul {
  margin: 0;
}

.form__message.error {
  background-color: #f44336;
}

.form__footer {
  display: flex;
  justify-content: flex-end;
}
