.ReactTable .rt-tr.selectable,
.ReactTable .rt-td.selectable {
  cursor: pointer;
}

.ReactTable .center {
  text-align: center;
}

.ReactTable .no-wrap {
  white-space: nowrap;
}

.ReactTable .loading {
  position: relative;
  border-radius: 0;
  top: -5px;
  left: -5px;
  width: 100%;
}

.ReactTable .checklist {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  margin: 5px;
  width: 100%;
}
.ReactTable .checklist .checklist__item {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: 5px;
  width: 20%;
}
.ReactTable .checklist .checklist__item span {
  margin-left: 5px;
}
