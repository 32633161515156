.dialog [role="dialog"] {
  overflow-y: unset;
}

.dialog .dialog__close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dialog__header {
  margin: 0 100px 25px 100px;
}
.dialog__header .dialog__icon {
  position: absolute;
  top: -20px;
  left: 50px;
  border-radius: 3px;
  background-color: #b49a70;
}
.dialog__header .dialog__icon > svg {
  font-size: 1.7em;
  margin: 10px;
  color: #fff;
}
.dialog__header .dialog__title {
  color: #999999;
}
.dialog__header .audit-info {
  position: absolute;
  top: 15px;
  right: 70px;
  text-align: right;
}

.dialog--confirm .dialog__header {
  margin-top: 50px;
  text-align: center;
}
.dialog--confirm .dialog__header .dialog__icon--done {
  font-size: 100px;
  font-weight: 900;
}

.dialog--confirm .dialog__content {
  margin-bottom: 20px;
}
.dialog--confirm .dialog__content p {
  text-align: center;
  padding: 10px;
}
